.wrapper {
  max-width: 1760px;
  width: 100%;
  margin:0 auto;
}

.topSection {
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
  z-index: 2;
}

.details {
  height: fit-content;
  width: 100%;
  max-width: 1710px;
  margin: 0 auto;
  padding: 0 15px;
  z-index: 22;
  margin-top: -400px;
  margin-left: 0;
  position: relative;
 
  & > img {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: -1;
  }
  padding: 56px 48px;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  & > div {
    & > span {
      cursor: pointer;
      font: 400 16px / 25.6px "Avenir Roman";
      color: #5a5a5a;
      position: relative;
      z-index: 1;
      & > img {
        position: absolute;
        left: -25px;
        top: 5px;
      }
    }
    & > h1 {
      font: 800 24px / 36px "Avenir Roman";
      color: #191919;
      text-align: left;
      width: 700px;
    }
    & > small {
      font: 400 12px / 18px "Avenir Roman";
      color: #5a5a5a;
    }
    & > p {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 5px;
      font: 400 14px / 21px "Avenir Roman";
      color: #5a5a5a;
      & > span {
        color: #2d2d2d !important;
      }
    }
  }
}

.btnContainer {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 8px 0;
}


.btnContainer button {
  width: 174px;
  height: 40px;
  padding: 8px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  color: #fff;
  background-color:rgba(24, 156, 222, 1);
  border: 1px solid rgba(24, 156, 222, 1);
  border-radius: 8px;
  font: 800 14px / 21px "Avenir Roman";
  cursor: pointer;
  &:hover{
    background-color: #1976d2;
  }
}


.tabsSection{
  height: 56px;
  width: 100%;
  max-width: 1710px;
  margin: 0 auto;
  padding: 0 15px;
  padding-inline: 24px;
  /* margin-top: 40px; */
  position: sticky;
  top: 0;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  border:none;
  box-shadow: rgba(0, 0, 0, 0.06 ) 0px 5px 12px;
}


.purchaseDetails{
   display:flex;
   justify-content:flex-start;
   align-items:center;
   & > p {
    font-size: 13px;
    color: #5a5a5a;
    margin: 10px 0;
    display:flex;
    align-items:center;
    & > span{
       color: #000;
       margin-left: 8px;
       margin-right: 4px;
    };
   };
   &>p:nth-child(1){
    border-right: 1px solid rgba(228, 228, 228, 1);
    padding-right: 10px;
    margin-right: 6px;
    margin-left: -8px;
 }
}

.hubName{
  display:inline-block;
  text-align: center;
  background: rgba(255, 237, 199, 1);
  color: rgba(169, 77, 25, 1) !important;
  font-size: 13px;
  font-weight: 800; 
  height: 26px;
  padding: 4px 10px 4px 10px;
  border-radius: 16px;
  gap: 16px;
}

.studies{
  color:rgba(90, 90, 90, 1);
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
   & span{
    color: rgba(45, 45, 45, 1);
    font-weight: 500; 
   }
}

.dot{
  display: inline-block;
  width:5px;
  height:5px;
  background:#000;
  border-radius:50%;
}

/* Company Profile */

.buttonsContainer{
  width: 100%;
  display: flex;
  justify-content:flex-end;
  align-items:center;
  & > button{
    border: 0.8px solid rgba(0, 47, 117, 1);
    border-radius: 8px;
    outline:none;
    display:flex;
    align-items:center;
    justify-content:center;
    height: 40px;
    padding: 8px 24px 8px 24px;
    color:rgba(0, 47, 117, 1);
    background: #fff;
    margin:  15px;   
    font-size: 14px;
    font-weight: 800;
    &:hover{
      cursor:pointer;
    }
     & img{
      margin-right: 8px;
     }
  }

}

.overviewContainer{
  /* background-color: rgba(244, 248, 250, 1); */
  width: 100%;
  margin-bottom:20px;
  padding: 20px;
}

.globalOverview{
  background-color: #fff;
  /* height: 720px; */
  width: 80%;
  margin: 0 auto;
  margin-bottom: 30px;

  & h1{
    font-size: 27px;
    color: #002f75;
    font-weight: 800;
    margin-bottom: 5px;
  }
  & .firstColumn{
     flex:0 1 32.5%;
     display:flex;
     height: 100% !important;
     flex-direction: column;
     justify-content: space-between;
     align-items: flex-start;
     & img{
       object-fit: cover;
       width: 100%;
       height: 100%;
     }
     & h3{
      color: #002F75;
      text-transform: uppercase;
      margin-bottom: 15px;
      font-size: 18px;
     }
     & ul{
       list-style: none;
       color: #002F75;
     }
     & li{
      margin-bottom: 15px;
     }
  }
  & .secondColumn{
    flex:0 1 32.5%;
    display: flex;
    flex-direction:column;
    justify-content: space-between;
    align-items: stretch;
    & > div{
      flex: 0 1 23%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: #002F75 !important;

    }
   
  }
  & .thirdColumn{
    flex:0 1 32.5%;
     background-color: #EEF1F2;
     padding: 10px;
     & h3{
      color: #002F75;
      font-size: 18px;
       text-transform: uppercase;
       margin-bottom: 15px;     
     }
     & ul{
       list-style: none;
       color: #002F75;
     }
     & li{
       margin-bottom: 15px;
       font-size: 16px;
     }
     & h5{
         font-weight: bold;
         font-size: 16px;
         margin-bottom: 10px;
     }
  }
}

.marketOverview{
  background-color: #fff;
  width: 80%;
  margin: 0 auto;
  height: 720px;
  margin-bottom: 30px;
   & h1{
    font-size: 27px;
    color: #002f75;
    font-weight: 800;
    margin-bottom: 5px;
   }
   & .fourthColumn{
    flex:0 1 32.5%;
    background:#E6E3FC;
    padding: 10px;
    & h3{
     color: #002F75;
     text-transform: uppercase;
     margin-bottom: 15px;
     font-size: 18px;
    }
    & ul{
      list-style: none;
      color: #002F75;
    }
    & li{
     margin-bottom: 15px;
    }
 }
   & .fifthColumn{
    flex:0 1 32.5%;
    display: flex;
    flex-direction:column;
    justify-content: space-between;
    align-items: stretch;
    & > div{
      flex: 0 1 23%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: #002F75 !important;

    }
   
  }

  & .sixthColumn{
    flex:0 1 32.5%;
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    & h3{
     color: #002F75;
     text-transform: uppercase;
     margin-bottom: 15px;
     font-size: 18px;
    }
    & ul{
      list-style: none;
      color: #002F75;
    }
    & li{
     margin-bottom: 15px;
    }
 }
  
}

.overview{
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  height: 94%;
}

.highlightsBox{
  display: flex ;
  flex-direction: column !important;
  align-items: flex-start !important;
  justify-content: flex-start !important;
  background:#3E87DD;
  flex:0 1 78% !important;
  padding: 12px;
  & h3{
    color:#fff;
    margin-bottom: 10px;
  }
  & ul{
    list-style: none;
    color:#fff;
  }
}


.productService{
  background-color: #fff;
  width: 80%;
  margin: 0 auto;
  margin-bottom: 30px;
   & h1{
    font-size: 27px;
    color: #002f75;
    font-weight: 800;
    margin-bottom: 10px;
   }
  &> div{
    display:flex;
    justify-content:space-between;
    align-items:stretch;
    height: fit-content;
    & table{
      flex: 0 1 49%;
      height: 100%;
    }
    & th{
      font-size: 20px;
      height: 40px;
      margin-top: 20px;
      color: #002f75;
      text-align: left;
      padding-left: 5px;
    }
    & td{
      border: 2px solid white;
      height: 100px;
      min-height: 80px;
      padding-left: 5px;
      color: #002f75;
    }
  }
}

.tableFir{
  & tr{
    background-color: #E7F7FE;
  }
  & td:first-child{
    width: 35%;
  }
  & td:last-child{
    width: 65%
  }
  & th:first-child{
    width: 35%;
  }
  & th:last-child{
    width: 65%
  }
}

.tableSec{
  & tr{
    background-color: #D7F6EB;
  }
  & td:first-child{
    width: 35%;
  }
  & td:last-child{
    width: 65%
  }
  & th:first-child{
    width: 35%;
  }
  & th:last-child{
    width: 65%
  }
}

.keyDevelopment{
  background-color: #fff;
  width: 80%;
  margin: 0 auto;
  margin-bottom: 30px;
   & h1{
    font-size: 27px;
    color: #002f75;
    font-weight: 800;
    margin-bottom: 10px;
   }

   & .tableThir{
    width: 100%;
    border-collapse: collapse;

    & tr:nth-child(odd){
      background-color: #D7F6EB;
    }
    & tr:nth-child(even){
      background-color: #E7F7FE;
    }
    & td:first-child{
      width: 15%;
    }
    & td:nth-child(2){
      width: 25%;
    }
    & td:last-child{
      width: 60%
    }
    & th{
      font-size: 20px;
      height: 30px;
      margin-top: 20px;
    color: #002f75;
    text-align: left;
    padding-left: 5px;
    }
    & td{
      padding-left: 5px;
      color: #002f75;
      height: 100px;
      min-height: 80px ;
    }

   }
}

.companyProfileLock{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 600px;
  & >div{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 2px 20px 0px rgba(0, 47, 117, 0.12);
    border-radius: 8px;
    height: 272px;
    width:504px;
    & h4{
      color: rgba(25, 25, 25, 1);
      font-size: 17px;
      margin-top: 25px ;
       margin-bottom: 16px;
    }
    & p{
      color: rgba(90, 90, 90, 1);
      font-size: 16px;
      margin-bottom: 18px;

    }
    & button{
      width:208px;
      height:52px;
      border-radius: 8px;
      outline: none;
      border: none;
      margin: 10px;
      cursor: pointer;
    }
    & .cancel{
      border: 1px solid rgba(0, 47, 117, 1);
      color: rgba(0, 47, 117, 1);
      background-color: #fff;
    }
    & .buy{
      background-color: rgba(24, 156, 222, 1);
      color:#fff;
    }
  }
}
