.container {
  padding: 1rem; /* p-4 in Tailwind */
  /* border: 1px solid #e2e8f0; border in Tailwind */
  /* border-radius: 0.375rem; rounded in Tailwind */
  /* box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.06); shadow-sm in Tailwind */
}

.title {
  color: #449ac6;
  font-size: 1.23rem;
  line-height: 1.75rem;
  font-weight: 700;
  margin: 1rem;

}

.section {
  margin-left: 1rem;
}

.sectionTitle {
 background-color: rgb(240, 247, 252);
  color: #449ac6 !important;
  cursor: pointer;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  font-size: 1rem;
  padding: 5px 5px 5px 15px;
}

.mainSectionTitle {
  color: #1a202c;
  font-weight: 600;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.subsectionTitle {
  color: #4a5568 !important;
  background-color: #fff !important;
  font-size: 1rem;
}

.subsections {
  margin-left: 1rem;
  background-color: #fff !important;
}
