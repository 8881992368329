@font-face {
  font-family: "Avenir";
  src: url(
    "../src/fonts/Regular/Avenir-Regular.ttf"
  ) format("truetype");
}

@font-face {
  font-family: "Avenir Roman";
  src: url("../src/fonts/AvenirRoman.otf") format("opentype");
}

@font-face {
  font-family: "Avenir Medium";
  src: url("../src/fonts/AvenirMedium.otf") format("opentype");
}

@font-face {
  font-family: "Avenir Heavy";
  src: url("../src/fonts/AvenirHeavy.otf") format("opentype");
}

@font-face {
  font-family: "regular_regular";
  src: url("../src/fonts/Regular/Regular-Regular.otf") format("opentype");
}

@font-face {
  font-family: "regular_medium";
  src: url("../src/fonts/Regular/Regular-Medium.otf") format("opentype");
}
@font-face {
  font-family: "regular_semibold";
  src: url("../src/fonts/Regular/Regular-Semibold.otf") format("opentype");
}




*,
body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family:"Avenir",system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
  user-select: none;
}

a {
  text-decoration: none;
}

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  background-color: #fff;
}

::-webkit-scrollbar-track {
  background: #e0e0e0;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #bbdbf2;
  border: 1px solid #fff;
}

::-webkit-scrollbar-thumb:hover {
  border-radius: 10px;
  border: 1px solid #fff;
  background-color: #fff;
}

.css-15v22id-MuiAccordionDetails-root {
  padding: 0 8px !important;
}

.css-13xfq8m-MuiTabPanel-root {
  padding: 10px !important;
}

object {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard syntax */
}

.ant-table
  .ant-table-container
  .ant-table-content
  table
  thead.ant-table-thead
  .ant-table-cell {
  color: #002f75;
  font-size: 16px;
}

.PhoneInputInput{
  border: none;
  outline: none;
  font-size: 16px;
}
.card_body {
  /* Add margin for left and right; */
  margin: 0 2.6rem;
  padding-top: 1rem;
}

.title-16{
  font-family: Avenir Medium;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; 
  color: var(--grey-100, #191919);
}

.tab{
  font-family: Avenir;
  color:#191919;
  border-bottom: 1px solid var(--grey-2-seperators, #E4E4E4);
}

.number-box{
  position: absolute;
  top:-30px;
  right: -35px;
  font-size: 2rem;
   background-color: #E6E3FC; /* Adjust the background color of the number box */
  color: #5241CE; /* Adjust the text color of the number */
  width: 80px; /* Adjust the width of the number box */
  height: 80px; /* Adjust the height of the number box */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%; /* Make it a circle */
  font-weight: bold;
  border: 0.5px solid #5241CE;
}

.blur{
  filter: blur(8px);
  position: relative;
  pointer-events: none;
}
.overlay-blur{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(8px);
  pointer-events: none;
}

.no-select {
  user-select: none;
}

.request-modal{
     border: 1px solid rgb(216, 216, 216);
     border-radius: 8px;
     padding: 16px 12px;
}

.request-modal h1{
  text-align: center;
  font-family: "Avenir Medium";
}

.request-modal p{
  text-align: center;
  font-size: 16px;
}

.request-modal .description{
  margin: 20px auto;
  color: rgb(112, 112, 112);
}

.request-modal .remaining-credits{
  font-family: "Avenir Medium";
  font-size: 18px;
  color:#1BB1F0;
}

.request-modal .remaining-credits span{
   font-weight: 600;
}

.request-modal form{
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: stretch;
   width:100%;
   gap:5px;
}

.request-modal form label{
  /* padding: 8px; */
  font-size: 16px;
  color:rgb(100,100,100)
}

.request-modal form textarea{
     height: 180px;
     padding: 8px;
     resize: vertical;
}

.request-modal small{
  color:red;
  font-size: 12px;
}

.request-modal .req-btn{
  margin-top: 10px;
}

.reports-toggle .ant-switch-handle{
  width: 20px !important; 
  height: 20px !important;
  display:flex;
  justify-content:space-between;
  align-items:center;
  margin-top: 3px;
}

.reports-toggle .ant-switch-inner{
  width: fit-content !important;
  justify-content:space-between;
  align-items:center;
  margin-top: 5px;
}

.request-tag{
   position: absolute;
   right:0;
   display: inline-block;
   background-color: rgba(0,255,0, .25);
   padding: 8px;
   border-radius: 12px;
   color: rgb(8, 71, 8)
}

.request-tag-hub{
  position: absolute;
  right:0;
  display: inline-block;
  background-color: rgba(0,255,0, .25);
  padding: 2px 8px;
  border-radius: 3px;
  color: rgb(8, 71, 8)
}

.credit-info-icon{
  width: 18px;
  height: 18px;
  &:hover{
    cursor: pointer;
  }
}

.hide-credit-info{
   display: none;
}

.credit-info-popover{
    border: 1px solid rgb(141, 141, 141);
    border-radius: 4px ;
    background: linear-gradient(to right,#189CDE 0%,#EAFFEA 0%,#EAF6FE 60%,#E3E1FC 115%);
    padding:12px 12px;
    position:absolute;
    top:40px; 
    left:8px;
    right:50px;
}

.reports-table .ant-table-thead {
  display: none;
}


#fc_frame{
  margin-bottom: 40px;
}

@media print {
  .print-page {
    margin: 8mm;
  }
}