.datasheet-container {
  position: relative;
}

.download-button {
  padding: 8px 24px;
  background-color: #189cde; /* Blue color for button */
  color: #fff; /* White text color */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 800;
  text-align: center;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  transition: background-color 0.3s;
  white-space: nowrap; /* Prevent text wrapping */
}

.download-button:hover {
  background-color: #0b7cb4; /* Darker blue color on hover */
}

.excel-container {
  position: relative;
  width: 100%;
  max-width: 85vw; /* Adjust width as needed */
  margin: 20px auto; /* Center the container */
  background-color: #f9f9f9; /* Match table UI background color */
  border: 1px solid #ccc; /* Match table UI border */
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); /* Match table UI box shadow */
}

.rect-hide {
  width: 100%;
  height: 20.80%;
  background-color: #f9f9f9;
  position: absolute;
}

.rect-title {
  text-transform: uppercase;
  font-size: 20.5px;
  font-weight: 800;
  white-space: nowrap; /* Prevent text wrapping */
  text-wrap:wrap;
  width: fit-content;
  max-width: 1500px;
  margin-top: -20px; /* Adjust this value to reduce height from the top */
}

.title-download-container {
  position: absolute;
  display: flex; /* Use Flexbox for horizontal alignment */
  align-items: center; /* Vertically center the items */
  gap: 30px;
  flex-wrap: nowrap; /* Prevent wrapping */
  width: 100%; /* Ensure container spans full width */
  padding-right: 20px;
}

.rect-hide.single-file .title-download-container {
  top: 60%;
  left: 20px;
}

.rect-hide:not(.single-file) .title-download-container {
  top: 60%;
  left: 20px;
}

.excel-container iframe {
  width: 100%;
  height: 100vh; /* Set the height to match the UI */
  border: none; /* Remove iframe border */
}

/* Media Queries for Responsiveness */
@media (max-width: 1200px) {
  .download-button {
    left: 75%;
  }

  .excel-container {
    max-width: 1500px; /* Adjust width as needed */
  }

  .rect-hide {
    height: 150px;
    background-color: #f9f9f9;
  }

  .rect-title {
    font-size: 20.5px;
  }

  .title-download-container {
    top: 55%; /* Adjusted from 60% */
  }
}

@media (max-width: 768px) {
  .download-button {
    left: 65%;
    font-size: 12px;
    padding: 5px 18px;
  }

  .rect-title {
    font-size: small;
  }
}

@media (max-width: 576px) {
  .download-button {
    left: 60%;
    font-size: 11px;
    padding: 4px 16px;
  }

  .rect-title {
    font-size: smaller;
  }
}

@media (max-width: 400px) {
  .download-button {
    left: 55%;
    font-size: 10px;
    padding: 3px 14px;
  }

  .rect-title {
    font-size: smallest;
  }
}
