/* PowerBIEmbed.css */
.embed-container {
  width: 85vw;
  height: 900px;
  padding-top: 20px;
  padding-bottom: 20px;
  margin: auto;
  position: relative;
  background-color: #fff; /* Optional: Set a background color */
}

.trial-banner {
  position: relative;
  width: 100%; /* Full width of the container */
  left:-60px;
}


.hide-trial-banner {
  position: absolute;
  width: calc(100% - 14.7vw);
  left: 7.4vw;
  top: 20px;
  height: 42px;
  background-color: #f5f5f5;
  z-index: 1;
}
