.detailsBox {
  margin-block: clamp(10px, 5.55vh, 80px);
  background-color: #fff;
  width: clamp(250px, 34.166vw, 492px);
  height: fit-content;
  padding: 64px 56px 64px 56px;
  padding-inline: clamp(26px, 3.88vw, 56px);
  padding-block: clamp(34px, 4.44vw, 64px);
  border-radius: 8px;
  & > h1 {
    font: 800 24px / 36px "Avenir Roman", "sans-serif";
    margin-bottom: 15px;
  }
}

.inputContainer {
  & > form {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
}

.actionContainer {
  display: flex;
  flex-direction: row !important;
  justify-content: space-between;
  align-items: center;
  & > div {
    display: flex;
    align-items: center;
    gap: 5px;
    font: 400 14px / 25.6px "Avenir Roman", "sans-serif";
    & > input[type="checkbox"] {
      accent-color: rgb(7, 113, 167) !important;
    }
    & > a {
      text-decoration: none;
      color: rgb(44, 142, 191);
      font: 800 14px / 25.6px "Avenir Roman", "sans-serif";
      cursor: pointer;
    }
  }
}

.loginContainer {
  display: flex;
  flex-direction: column;
  gap: 1.2rem !important;
  font: 400 14px / 25.6px "Avenir Roman", "sans-serif";
  & > button {
    background-color: #189cde;
    color: #fff;
    width: 100%;
    height: 52px;
    border-radius: 8px;
    border: none;
    font: 800 14px / 25.6px "Avenir Roman", "sans-serif";
    cursor: pointer;
  }
  & > p {
    margin-inline: auto;
    & > a {
      text-decoration: none;
      color: #189cde;
    }
  }
}

.errorInput {
  font: 400 clamp(12px, 1.11vw, 16px) / 25.6px "Avenir Roman";
  color: #cf0a0a;
}
