.searchInput{
  height: "100%";
  flex:0 1 97%;
  border: none;
  outline:none;
  padding-left: 5px;
   & :hover{
    border:none;
    outline:none;
  }
}

.selectOptions{
   display: flex;
   flex-direction: column;
   justify-content: flex-start;
   align-items: flex-start;
   padding: 15px;
   padding-right: 0;
   color:  rgba(45, 45, 45, 1);
   border-top:1px solid rgba(213, 213, 213, 1);
   border-bottom:1px solid rgba(213, 213, 213, 1);
   overflow-y: scroll;
   height: 235px;
} 

.footer{
  width: 100%;
  padding: 20px 10px ;
  display:flex;
  justify-content:space-between;
  padding: 30px 15px;
  & >button{
    flex: 0 1 45%;
    border: none;
    outline:none;
    padding:7px 14px;
    height: 38px;
    box-shadow: rgba(161, 161, 184, 0.25) 0px 2px 5px -1px, rgba(71, 71, 71, 0.3) 0px 1px 3px -1px;
    border-radius:8px;
    &:hover{
      cursor: pointer;
    }
  }
  & .clearbtn{
    color: rgba(24, 156, 222, 1);
    background: #fff;
    
  }
  & .applybtn{
    background: rgba(24, 156, 222, 1);
    color: #fff;
  }
}

.buttonsContainer{
  width: 100%;
  display: flex;
  justify-content:flex-end;
  align-items:center;
  margin-bottom:10px;
  & > button{
    border: 0.8px solid rgba(0, 47, 117, 1);
    border-radius: 8px;
    outline:none;
    display:flex;
    align-items:center;
    justify-content:center;
    height: 40px;
    padding: 8px 24px 8px 24px;
    color:rgba(0, 47, 117, 1);
    background: #fff;
    margin:  15px;   
    font-size: 14px;
    font-weight: 800;
    &:hover{
      cursor:pointer;
    }
     & img{
      margin-right: 8px;
     }
  }
}

.tagContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    height: 100%;
    overflow-x: auto;
}

.tagContainer::-webkit-scrollbar {
  display: none;
}

.tag{
  border: 1px solid blue;
  height: 32px;
  padding: 6px 12px 6px 16px;
  border: 0.5px solid rgba(28, 147, 123, 1);
  border-radius: 48px;
  color: rgba(28, 147, 123, 1);
  background:linear-gradient(0deg, #D7F6EB, #D7F6EB);
  display: flex;
  justify-content:center;
  align-items: center;
  margin: 10px;
  & span{
    font-size: 13px;
    line-height: 18px;
  }
  & span:first-child{
    margin-right: 6px;
  }
  & .delete{
    &:hover{
      cursor:pointer;
    }
  }
}

.accessDeniesPopup{
    width:480px ;
    min-width: 360px;
    height: 260px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: rgba(14, 30, 37, 0.08) 0px 2px 4px 0px, rgba(14, 30, 37, 0.20) 0px 2px 16px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    & > h2{
      font-size: 16px;
      color: #000;
      margin: 0px auto;
      margin-top: 20px;
    }
    &> p{
      font-size: 14px;
      color:#696969;
      margin: 15px auto;
    }
    &>.button{
      background-color: #189CDE;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 90%;
      padding: 20px;
    }
}


