.filtersContainer {
  position: absolute;
  width: fit-content;
  height: fit-content;
  border-radius: 8px;
  box-shadow: 0px 2px 20px 0px #002f7514;
  background-color: #ffffff;
  top: 4rem;
  right: 0;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.topFilterSection {
  padding: 16px;
  & > p {
    font: 800 16px / 25.6px "Avenir Roman";
  }
}

.bottomFilterSection {
  display: flex;
  padding: 16px;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  & > button {
    width: 100%;
    height: 40px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    font: 800 14px / 21px "Avenir Roman";
    color: #878787;
    cursor: pointer;
  }
  & > button:nth-child(1) {
    background-color: #fff;
  }
  & > button:nth-child(2) {
    background-color: #d5d5d5;
  }
  & > button:disabled {
    cursor: not-allowed;
  }
}

.activeFilterBtn {
  background-color: #189cde !important;
  color: #fff !important;
}

.mainFilterContainer {
  position: relative;
  display: flex;
  & > button {
    width: 120px;
    height: 52px;
    padding: 8px 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border: 1px solid #002F75;
    background: transparent;
    color: #002F75;
    font: 800 16px / 25.6px "Avenir Roman";
    border-radius: 8px;
    cursor: pointer;
  }
}