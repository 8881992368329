.wrapper {
  width: 100%;
  height: 100%;
}

.topSection {
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
  z-index:2;
}

.details {
  height: fit-content;
  width: 100%;
  z-index: 22;
  margin-top: -400px;
  position: relative;
  & > img {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: -1;
    /* width: clamp(200px, 90%, 900px); */
  }
  padding: 5px 48px 23px 4px;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  & > div {
    & > span {
      cursor: pointer;
      font: 400 16px / 25.6px "Avenir Roman";
      color: #5a5a5a;
      position: relative;
      z-index: 1;
      & > img {
        position: absolute;
        left: -25px;
        top: 5px;
      }
    }
    & > h1 {
      font: 800 24px / 36px "Avenir Roman";
      color: #191919;
      text-align: left;
      width: 700px;
    }
    & > small {
      font: 400 12px / 18px "Avenir Roman";
      color: #5a5a5a;
    }
    & > p {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 5px;
      font: 400 14px / 21px "Avenir Roman";
      color: #5a5a5a;
      & > span {
        color: #2d2d2d !important;
      }
    }
  }
}

.reportSmallDetails {
  & > ul {
    display: flex;
    gap: 2rem;
    align-items: center;
  }
  & > ul > li:nth-child(1){
    list-style: none;
  }
}

.hubName {
  padding: 4px 8px;
  border-radius: 16px;
  background-color: #FFEDC7;
  color : #A94D19;
  font: 800 12px / 18px "Avenir Roman";
}

.btnContainer {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 8px 0;
  margin-top: 1rem;
}

.btnContainer button:nth-child(1) {
  width: fit-content;
  height: 40px;
  padding: 8px 24px;
  border-radius: 8px;
  gap: 8px;
  border: none;
  background-color: #189cde;
  color: #fff;
  font: 800 14px / 21px "Avenir Roman";
  cursor: pointer;
}

.btnContainer button:nth-child(2) {
  width: 188px;
  height: 40px;
  border-radius: 8px;
  border: none;
  padding: 8px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  color: #002f75;
  background-color: #fff;
  border: 0.8px solid #002f75;
  font: 800 14px / 21px "Avenir Roman";
  white-space: nowrap;
  cursor: pointer;
}

.btnContainer button:nth-child(3) {
  width: fit-content;
  height: 40px;
  border-radius: 8px;
  border: none;
  background-color: transparent;
  color: #189cde;
  font: 800 14px / 21px "Avenir Roman";
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  cursor: pointer;
  white-space: nowrap;
}

.tabsSection{
  width: 100%;
  height: 56px;
  padding-inline: 24px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.miniReportHead {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 5px;
  & > a:hover {
    text-decoration: underline;
  }
}