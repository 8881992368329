.validPassContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 1rem;
  & > li {
    list-style: none;
    display: flex;
    align-items: center;
    gap: 10px;
    font: 400 14px / 21px "Avenir Roman", "sans-serif";
    color: #191919;
  }
}
