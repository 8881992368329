.companyCard {
  margin-block: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  border-radius: 8px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  background-color: white;
  font-family: Arial, sans-serif;
  height: 85px;
  cursor: pointer;
}

.companyCard:hover {
  border: 1px solid rgb(24, 156, 222);
}

.companyInfo {
  display: flex;
  align-items: center;
}

.companyLogo {
  width: 65px;
  height: 65px;
  margin-right: 16px;
}

.companyDetails {
  display: flex;
  flex-direction: column;
}

.companyName {
  margin: 0;
  font-size: 1.2em;
  font-weight: bold;
}

.companyMeta {
  color: #666;
  font-size: 0.9em;
}

.reportInfo {
  display: flex;
  gap: 24px;
  text-align: center;
  & > p {
    text-align: left;
  }
}

.reportInfo div {
  font-size: 0.9em;
}

.reportInfo strong {
  display: block;
  font-size: 1em;
  color: #333;
}

.reportInfo p {
  margin: 4px 0 0;
  color: #333;
}
