.companyReportsButtonCnt {
  & > button {
    white-space: nowrap;
    width: 153px;
    height: 40px;
    padding: 8px 24px;
    border-radius: 8px;
    gap: 16px;
    background-color: transparent;
    font: 500 14px / 21px "Avenir Roman";
    border: none;
    color: #5a5a5a;
    cursor: pointer;
  }
}

.activeBtn {
  background-color: #446ba6 !important;
  color: #fff !important;
  font: 800 14px / 21px "Avenir Roman" !important;
  width: fit-content !important;
}

.mainSearchFilterContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 0.5rem;
}

.searchContainer {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.searchMainContainer {
  width: 100%;
  position: relative;
  & > img {
    position: absolute;
    left: 15px;
    top: 10px;
    width: 30px;
    height: 30px;
  }
  & > input {
    height: 50px;
    width: inherit;
    padding: 12px 60px;
    border-radius: 8px;
    border: 1px solid #d5d5d5;
    gap: 16px;
    & > ::placeholder {
      font: 500 20px / 25.6px "Avenir Roman";
      color: #b4b4b4;
    }
  }
}
