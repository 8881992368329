.paginationMainContainer {
  margin-inline: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
  gap: 1rem;
  font: 400 14px / 21px "Avenir Roman";
  & > div {
    display: flex;
    align-items: center;
    gap: 1rem;
    & > select {
      background: #e0effa;
      padding: 8px;
      border-radius: 8px;
      border: none;
    }
    & > button {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      background: linear-gradient(0deg, #ffffff, #ffffff),
        linear-gradient(0deg, #002f75, #002f75);
      width: 36px;
      height: 36px;
      border-radius: 8px;
      border: 1px solid #002f75;
    }
  }
}