.mainContainer {
  max-width: 100% !important;
  display: flex;
}

.reportTitle {
  font: 800 20px / 30px "Avenir Roman" !important;
  color: #191919;
}

.navContainer {
  background: transparent;
  border-radius: 8px;
  height: fit-content;
  width: 25%;
  display: block;
  position: relative;
  position: sticky;
  position: -webkit-sticky;
  top: 70px;
  align-self: flex-start;
  transform: translateY(0);
}

.templateNavContainer {
  background: transparent;
  border-radius: 8px;
  height: fit-content;
  display: block;
  position: relative;
  position: sticky;
  position: -webkit-sticky;
  top: 70px;
  align-self: flex-start;
  transform: translateY(0);
}

.mainNavList {
  box-shadow: 0px 2px 20px 0px #002f7514 !important;
  & > li {
    list-style: none;
    border-bottom: 1px solid #e4e4e4;
    padding: 16px 24px !important;
    font: 800 16px / 25.6px "Avenir Roman" !important;
    cursor: pointer;
  }
}
.aboutReportLink {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font: 800 16px / 25.6px "Avenir Roman" !important;
}
.aboutReportLink:hover {
  background-color: none;
}

.navListItem {
  font: 500 16px / 25.6px "Avenir Roman";
  height: 58px;
  display: flex;
  align-items: center;
  padding-left: 15px !important;

  /* border-left: 1px solid #D5D5D5 */
}

.templateNavListItem {
  font: 500 16px / 25.6px "Avenir Roman";
  height: 58px;
  display: flex;
  align-items: center;
  padding-left: 15px !important;
  margin-block: 0 !important;
  border-radius: 0 !important;
}
.templateNavListItem:hover {
  background-color: none !important;
}


.buyNowButton{
  width: 100%;
  padding: 15px 10px;
  height: 40px;
  background-color: #b30f1a;
  color: #fff;
  font: 800 16px / 21px "Avenir Roman" !important;
  border: none;
  border-radius: 10px; 
  margin-top: 2rem !important;
}

.buyNowButton:focus{
  outline: none;
}

.buyNowButton:active{
  transform: translateY(-2px);
}

.headingTitle {
  font: 800 20px / 30px "Avenir Roman" !important;
}

.minorHeading {
  font: 800 16px / 30px "Avenir Roman" !important;
  color: #195571;
}

.blockHeading {
  flex: 0 1 12%;
  min-height: 20px !important;
  line-height: 1.25;
  margin-bottom: 5px;
  color: #1f4986 !important;
  text-transform: uppercase;
  margin-top: 10px !important;
  padding-top: 0 !important;
  margin-bottom: 10px !important;
  font-family: "Avenir Roman" !important;
}

.minorList {
  overflow: auto hidden;
  transition: all .6s ease-in-out;
  & > li {
    border-left: 1px solid #D5D5D5;
  }
}

.iconButton {
  outline: none;
}

.iconButton:focus {
  outline: none;
}

.getAccessBtnCnt {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem !important;
}