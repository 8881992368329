.infoContainer {
  margin-block: 2rem;
}

.heading {
  color: #449ac6;
  font-size: 1.73rem;
  line-height: 1.75rem;
  font-weight: 700;
  margin: 1rem;
}

.content {
  color: rgb(39, 39, 42);
  font-size: 1.2rem;
  line-height: 1.8rem;
  margin: 1rem;

  & > table {
    border-collapse: collapse;
    width: 100%;
    /* min-width: 40%; */
    /* margin-bottom: 1rem; */
    border: 1px solid var(--grey-1-background, #ECECEC);
    border-radius: 8px;
    /* margin-left: 0.5rem; */
    /* margin-right: 0.5rem; */
    /* margin-top:1rem; */
    & > thead {
      border-bottom: 1px solid var(--grey-1-background, #ECECEC);;
      & > tr {
        font-weight: 700;
        text-align: left;
        & > th {
          padding: 0.2rem;
          border-right: 1px solid var(--grey-1-background, #ECECEC);
        }
      }
    }
    & > tbody {
      & > tr {
        border-bottom: 1px solid var(--grey-1-background, #ECECEC);
        & > th {
          text-align: left;
          padding:0.5rem;
          padding-left: 0.3rem;
          border-right: 1px solid var(--grey-1-background, #ECECEC);
        }
        & > td {
          & > strong{
            font-weight: 600;
          }
          border-right: 1px solid var(--grey-1-background, #ECECEC);
          padding: 0.5rem;
          font-size: 0.9rem;
        }
      }
      &>tr:last-child{
        border-bottom: none;
      }
    }
    tbody tr:nth-child(odd) {
      background-color: rgb(255, 255, 255);
    }

    tbody tr:nth-child(even) {
      background-color: rgb(240, 247, 252);
    }
    
  }

  & > p {
    margin-block: 1rem;
    color: rgb(90, 90, 90);
    font-size: 16px;
    line-height: 1.6rem;
    letter-spacing: 0.17136px;
    padding: 0rem 0.5rem;
  }

  & > ul {
    color: rgb(90, 90, 90);
    font-size: 16px;
    line-height: 1.6rem;
    letter-spacing: 0.17136px;
    list-style-type: disc;
    margin-inline-start: 2.2rem;
    & > li {
      margin-block: 0.5rem;
      color: rgb(90, 90, 90);
    font-size: 16px;
    line-height: 1.6rem;
    letter-spacing: 0.17136px;
    }
  }

  & > ol {
    color: rgb(90, 90, 90);
    font-size: 16px;
    line-height: 1.6rem;
    letter-spacing: 0.17136px;
    list-style-type:decimal;
    margin-inline-start: 2.2rem;
    & > li {
      margin-block: 0.5rem;
    color: rgb(90, 90, 90);
    font-size: 16px;
    line-height: 1.6rem;
    letter-spacing: 0.17136px;
    }
  }

  & > h3 {
    color: rgb(90, 90, 90);
    font-size: 16px;
    line-height: 1.6rem;
    letter-spacing: 0.17136px;
    font-size: 1rem;
    line-height: 1.8rem;
    font-weight: 700;
    margin-block: 1rem;
    padding: 0rem 0.5rem
  }
}
