.infoContainer {
  margin-block: 2rem;
}

.heading {
  color: #449ac6;
  font-size: 1.23rem;
  line-height: 1.75rem;
  font-weight: 700;
  margin: 1rem;
}

.content {
  color: rgb(90, 90, 90);
  font-size: 16px;
  line-height: 1.6rem;
  margin: 1rem;
  letter-spacing: 0.17136px;

  p{
    margin-bottom: 1rem;
  }
  
}