.detailsBox {
  margin-block: clamp(10px, 5.55vh, 80px);
  background-color: #fff;
  width: clamp(250px, 34.166vw, 492px);
  height: fit-content;
  padding: 64px 56px 64px 56px;
  padding-inline: clamp(26px, 3.88vw, 56px);
  padding-block: clamp(34px, 4.44vw, 64px);
  border-radius: 8px;
  & > h1 {
    font: 800 24px / 36px "Avenir Roman", "sans-serif";
    margin-bottom: 15px;
  }
  & > p {
    font: 400 16px / 25.6px "Avenir Roman", "sans-serif";
    color: #5a5a5a;
    margin-bottom: 1rem;
  }
}

.inputContainer {
  & > form {
    display: flex;
    flex-direction: column;
    gap: 15px;
    & > div {
      display: flex;
      flex-direction: column;
      gap: 5px;
      & > label {
        font: 800 14px / 21px "Avenir Roman", "sans-serif";
        color: #2d2d2d;
      }
      & > input {
        height: 46px;
        width: 100%;
        padding: 12px;
      }
      & > input::placeholder {
        color: #878787;
        font: 400 16px / 25.6px "Avenir Roman", "sans-serif";
      }
      & > input:focus {
        outline-color: #2d2d2d;
      }
      & > input[type="password"] {
        font-size: larger;
      }
    }
  }
}

.loginContainer {
  display: flex;
  flex-direction: column;
  gap: 1.2rem !important;
  font: 400 14px / 25.6px "Avenir Roman", "sans-serif";
  & > button {
    background-color: #189cde;
    color: #fff;
    width: 100%;
    height: 52px;
    border-radius: 8px;
    border: none;
    font: 800 14px / 25.6px "Avenir Roman", "sans-serif";
    cursor: pointer;
  }
  & > a {
    text-decoration: none;
    margin-inline: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.6rem;
    font: 800 16px / 25.6px "Avenir Roman", "sans-serif";
    color: #189cde;
    margin-top: 1.5rem;
  }
}

.errorInput {
  font: 400 clamp(12px, 1.11vw, 16px) / 25.6px "Avenir Roman";
  color: #cf0a0a;
}

.disabledBtn {
  background-color: #d5d5d5 !important;
  font: 800 16px / 25.6px "Avenir Roman", "sans-serif";
  color: #878787 !important;
  cursor: not-allowed !important;
}

.dialogModal {
  width: 404px;
  height: 220px;
  padding: 32px 48px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  & > h4 {
    font: 800 16px / 25.6px "Avenir Roman", "sans-serif";
    color: #191919;
  }
  & > p {
    font: 400 16px / 25.6px "Avenir Roman", "sans-serif";
    color: #5a5a5a;
  }
}
