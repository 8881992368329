.wrapper {
  width: 100%;
  height: 100%;
}

.mainReportContainer {
  padding-inline: 4%;
}

.topSection {
  height: fit-content;
  display: flex;
  align-items: flex-start;
  position: relative;
  z-index: 22;
  margin-top: -90px;
  margin-bottom: 44px;
  gap: 1.5rem;
  position: relative;
  & > button {
    white-space: nowrap;
    width: 153px;
    height: 40px;
    padding: 8px 24px;
    border-radius: 8px;
    gap: 16px;
    background-color: transparent;
    font: 500 14px / 21px "Avenir Roman";
    border: none;
    color: #5a5a5a;
    cursor: pointer;
  }
}

.activeBtn {
  background-color: #446ba6 !important;
  color: #fff !important;
  font: 800 14px / 21px "Avenir Roman" !important;
  width: fit-content !important;
}

.mainChildrenSection {
  display: flex;
  gap: 2rem;
  align-items: flex-start;
  justify-content: flex-start;
  & > section {
    height: inherit;
  }
  & > section:nth-child(1) {
    width: 350px;
    flex-shrink: 0;
  }
  & > section:nth-child(2) {
    padding-top: 44px;
    width: 100%;
  }
}

.myReportsMainContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.searchMainContainer {
  width: 100%;
  position: relative;
  & > img {
    position: absolute;
    left: 15px;
    top: 10px;
    width: 30px;
    height: 30px;
  }
  & > input {
    height: 50px;
    width: inherit;
    padding: 12px 60px;
    border-radius: 8px;
    border: 1px solid #d5d5d5;
    gap: 16px;
    & > ::placeholder {
      font: 500 20px / 25.6px "Avenir Roman";
      color: #b4b4b4;
    }
  }
}

.paginationMainContainer {
  margin-inline: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
  gap: 1rem;
  font: 400 14px / 21px "Avenir Roman";
  & > div {
    display: flex;
    align-items: center;
    gap: 1rem;
    & > select {
      background: #e0effa;
      padding: 8px;
      border-radius: 8px;
      border: none;
    }
    & > button {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      background: linear-gradient(0deg, #ffffff, #ffffff),
        linear-gradient(0deg, #002f75, #002f75);
      width: 36px;
      height: 36px;
      border-radius: 8px;
      border: 1px solid #002f75;
    }
  }
}

.categoryPathContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  & > section {
    display: flex;
    align-items: center;
    gap: 1rem;
    font: 800 24px / 36px "Avenir Roman";
    color: #000;
    & > div {
      font: 400 16px / 25.6px "Avenir Roman" !important;
      color: #5a5a5a !important;
    }
    & > button {
      background: transparent;
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      cursor: pointer;
    }
  }
}

.mainFilterContainer {
  position: relative;
  display: flex;
  & > button {
    width: 120px;
    height: 52px;
    padding: 8px 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border: 1px solid #002F75;
    background: transparent;
    color: #002F75;
    font: 800 16px / 25.6px "Avenir Roman";
    border-radius: 8px;
    cursor: pointer;
  }
}

.filtersContainer {
  position: absolute;
  width: 482px;
  height: fit-content;
  border-radius: 8px;
  box-shadow: 0px 2px 20px 0px #002f7514;
  background-color: #ffffff;
  top: 4rem;
  right: 0;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.topFilterSection {
  padding: 16px;
  & > p {
    font: 800 16px / 25.6px "Avenir Roman";
  }
}

.middleFilterSection {
  display: flex;
  height: 100%;
  border-top: 1px solid #e4e4e4;
  border-bottom: 1px solid #e4e4e4;
  max-height: 432px;
  & > div {
    width: 100%;
    padding: 1rem;
    & > h6 {
      font: 500 14px /21px "Avenir Roman";
      color: #5a5a5a;
    }
    & > section {
      max-height: 300px;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 0.5rem;
      padding-top: 1rem;
      & > div {
        display: flex;
        flex-direction: row !important;
        align-items: center;
        gap: 0.5rem;
        & > input {
          width: 18px;
          height: 18px;
          border: 1.5px solid #b4b4b4;
        }
        & > input[type="checkbox"] {
          accent-color: #178BC6;
        }
        & > label {
          width: fit-content;
          font: 400 14px / 21px "Avenir Roman";
          color: #2d2d2d;
        }
      }
    }
  }
  & > div:nth-child(1) {
    border-right: 1px solid #e4e4e4;
  }
}

.bottomFilterSection {
  display: flex;
  padding: 16px;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  & > button {
    width: 100%;
    height: 40px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    font: 800 14px / 21px "Avenir Roman";
    color: #878787;
    cursor: pointer;
  }
  & > button:nth-child(1) {
    background-color: #fff;
  }
  & > button:nth-child(2) {
    background-color: #d5d5d5;
  }
  & > button:disabled {
    cursor: not-allowed;
  }
}

.activeFilterBtn {
  background-color: #189cde !important;
  color: #fff !important;
}

.mainSearchFilterContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 0.5rem;
}

.searchContainer {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.tagContainer {
  width: 100%;
}

.tagsMainContainer {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  & > p {
    position: relative;
    width: fit-content;
    height: fit-content;
    font: 500 14px / 21px "Avenir Roman";
    color: #2d2d2d;
    padding: 8px 35px 8px 12px;
    background-color: #D7F6EB;
    border: 0.5px solid #1C937B;
    display: flex;
    flex-direction: center;
    justify-content: center;
    border-radius: 48px;
    & > span {
      position: absolute;
      right: 10px;
      font-size: larger;
      cursor: pointer;
      top: 6px;
    }
  }
}
