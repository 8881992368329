.wrapper {
  width: 100%;
  height: 100%;
  min-height: 60vh;
}

.mainCompanyContainer {
  padding-inline: 4%;
}

.topSection {
  height: fit-content;
  display: flex;
  align-items: flex-start;
  position: relative;
  z-index: 22;
  margin-bottom: 44px;
  gap: 3rem;
  position: relative;
  & > button {
    white-space: nowrap;
    width: fit-content;
    height: 40px;
    padding: 8px 24px;
    border-radius: 8px;
    gap: 16px;
    background-color: transparent;
    font: 500 14px / 21px "Avenir Roman";
    border: none;
    color: #5a5a5a;
    cursor: pointer;
  }
}

.activeBtn {
  background-color: #446ba6 !important;
  color: #fff !important;
  font: 800 14px / 21px "Avenir Roman" !important;
  width: fit-content !important;
}

.searchMainContainer {
  width: 100%;
  position: relative;
  & > img {
    position: absolute;
    left: 15px;
    top: 10px;
    width: 30px;
    height: 30px;
  }
  & > input {
    height: 50px;
    width: inherit;
    padding: 12px 60px;
    border-radius: 8px;
    border: 1px solid #d5d5d5;
    gap: 16px;
    & > ::placeholder {
      font: 500 20px / 25.6px "Avenir Roman";
      color: #b4b4b4;
    }
  }
}

.mainSearchFilterContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 0.5rem;
}

.searchContainer {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.childrenContainer {
  padding-top: 3rem;
}

.categoryPathContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  & > section {
    display: flex;
    align-items: center;
    gap: 1rem;
    font: 800 24px / 36px "Avenir Roman";
    color: #000;
    & > div {
      font: 400 16px / 25.6px "Avenir Roman" !important;
      color: #5a5a5a !important;
    }
    & > button {
      background: transparent;
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      cursor: pointer;
    }
  }
}

.companyInfoSection {
  display: flex;
  justify-content: flex-start !important;
  align-items: flex-start !important;
  gap: 1rem;
  width: 100%;
}

.aboutCompanySectionContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.selectedCompanyImg {
  height: 48px;
  width: 48px;
  display: flex;
  align-items: flex-start;
  & > img {
    object-fit: cover;
    width: 100%;
  }
}

.aboutCompanyDetails {
  display: flex;
  justify-content: flex-start;
  gap: 2.5rem;
}
